import { LOG_OUT, SET_USER } from '../actions/userActions';

const defaultState = {
  loggedIn: false,
  user: {}
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        loggedIn: true,
        user: { ...action.payload }
      };
    case LOG_OUT:
      return {
        loggedIn: false,
        user: {}
      };
    default:
      return state;
  }
};

export default userReducer;
