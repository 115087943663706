import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarOutLink from './SidebarOutLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const {
      changeToDark,
      changeToLight
    } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/*<SidebarLink title="Log In" icon="exit" route="/log_in" onClick={this.hideSidebar}/>*/}
          <SidebarCategory title="테마" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">어두운 테마</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">밝은 테마</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="서비스 이용 내역" icon="list">
            <SidebarLink title="접속 내역" icon="enter" route="/connection-logs" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="정책 위반 내역" icon="warning" route="/suspicion-logs" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="서비스 이용 내역" icon="list" route="/service-logs" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="신고 내역" icon="sad" route="/report-logs" isSubMenu="true" onClick={this.hideSidebar}/>
          </SidebarCategory>
          <SidebarCategory title="사용자 관리" icon="users">
            <SidebarLink title="사용자 관리" icon="users" route="/users" isSubMenu="true" onClick={this.hideSidebar}/>
          </SidebarCategory>
          <SidebarCategory title="단어 관리" icon="database">
            <SidebarLink title="한국어 단어 관리" icon="database" route="/words-ko" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="영어 단어 관리" icon="database" route="/words-en" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="한국어 단어 관리 내역" icon="list" route="/word-audits-ko" isSubMenu="true" onClick={this.hideSidebar}/>
            <SidebarLink title="영어 단어 관리 내역" icon="list" route="/word-audits-en" isSubMenu="true" onClick={this.hideSidebar}/>
          </SidebarCategory>
          <SidebarLink title="상점 데이터 관리" icon="store" route="/shop" onClick={this.hideSidebar}/>
          <SidebarCategory title="도움말" icon="question-circle">
            <button type="button" className="sidebar__link" route="/kb/moderation" onClick={this.hideSidebar}>
              <p className="sidebar__link-title">운영팀 도움말</p>
            </button>
            <button type="button" className="sidebar__link" route="/kb/word" onClick={this.hideSidebar}>
              <p className="sidebar__link-title">단어팀 도움말</p>
            </button>
          </SidebarCategory>
        </ul>
        <SidebarOutLink title="끄투리오"
                        link="https://kkutu.io"/>
        <SidebarOutLink title="끄투리오 노션"
                        link="https://www.notion.so/kkutuio/e34de413ed6841359c9ba3c24965bd31"/>
        <SidebarOutLink title="끄투리오 사용자 규정"
                        link="https://kkutuio.notion.site/aa92028fc4284743892cf4e26755bf6f"/>
        <SidebarOutLink title="끄투리오 디스코드"
                        link="https://discord.gg/hzZa2YsfZQ"/>
        <SidebarOutLink title="끄투리오 공식카페"
                        link="https://cafe.naver.com/kkutuio"/>
      </div>
    );
  }
}

export default SidebarContent;
