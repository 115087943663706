import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import UserAuthentication from './UserAuthentication';

const ConnectionLogs = React.lazy(() => import('../ConnectionLog'));
const SuspicionLogs = React.lazy(() => import('../SuspicionLog'));
const ReportLogs = React.lazy(() => import('../ReportLog'));
const KoreanWords = React.lazy(() => import('../KoreanWords'));
const EnglishWords = React.lazy(() => import('../EnglishWords'));
const KoreanWordEdit = React.lazy(() => import('../KoreanWords/components/WordEdit'));
const EnglishWordEdit = React.lazy(() => import('../EnglishWords/components/WordEdit'));
const KoreanWordAudits = React.lazy(() => import('../KoreanWordAudits'));
const EnglishWordAudits = React.lazy(() => import('../EnglishWordAudits'));

const wrappedRoutes = () => (
  <div>
    <Layout/>
    <div className="container__wrap">
      <React.Suspense fallback={<h1>Please wait...</h1>}>
        <Switch>
            <Route path="/connection-logs" component={ConnectionLogs}/>
            <Route path="/suspicion-logs" component={SuspicionLogs}/>
            <Route path="/report-logs" component={ReportLogs}/>
            {/*<Route path="/users" component={ExamplePageTwo}/>*/}
            <Route exact path="/words-ko" component={KoreanWords}/>
            <Route path="/words-ko/:type/:id?" component={KoreanWordEdit}/>
            <Route exact path="/words-en" component={EnglishWords}/>
            <Route path="/words-en/:type/:id?" component={EnglishWordEdit}/>
            <Route exact path="/word-audits-ko" component={KoreanWordAudits}/>
            <Route exact path="/word-audits-en" component={EnglishWordAudits}/>
        </Switch>
      </React.Suspense>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <UserAuthentication>
        <Switch>
          <Route path="/" component={wrappedRoutes}/>
        </Switch>
      </UserAuthentication>
    </main>
  </MainWrapper>
);

export default Router;
