import { KKuTuIOApi } from '../../KKuTuIOApi';

export const SET_USER = 'SET_USER';
export const LOG_OUT = 'LOG_OUT';

const setUser = (payload) => ({
  type: SET_USER,
  payload
});

export const logout = () => ({ type: LOG_OUT });

export const fetchUser = () => dispatch => {
  KKuTuIOApi.get('/profile')
    .then(({ data }) => {
      if (data) {
        dispatch(setUser(data));
      }
    });
};
