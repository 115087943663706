import React, { useEffect } from 'react';
import { fetchUser } from '../../redux/actions/userActions';
import { connect, useDispatch } from 'react-redux';
import Login from '../Login';

const UserAuthentication = ({
  userStatus,
  children
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());

  }, []);

  const loggedIn = userStatus.loggedIn;
  if (loggedIn) {
    return children;
  } else {
    return <Login/>;
  }
};

const mapStateToProps = state => {
  return {
    userStatus: state.user
  };
};

export default connect(mapStateToProps)(UserAuthentication);
