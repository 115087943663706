import { applyMiddleware, combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { rtlReducer, sidebarReducer, themeReducer, userReducer } from '../../redux/reducers/index';
import ReduxThunk from 'redux-thunk';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  user: userReducer
});

const store = createStore(reducer, applyMiddleware(ReduxThunk));

export default store;
