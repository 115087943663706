import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class TopbarProfile extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const userStatus = this.props.userStatus;
    const user = userStatus.user;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar">
          <img className="topbar__avatar-img"
               src="https://cdn.kkutu.io/img/bi/bi_profile_white.png"
               alt="avatar"/>
          <p className="topbar__avatar-name"><b>{user.team}</b> {user.name}</p>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userStatus: state.user
  };
};

export default connect(mapStateToProps)(TopbarProfile);
