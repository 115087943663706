import Axios from 'axios';

// const API_HOST = 'http://home.horyu.me:20600';
const API_HOST = 'https://kkutu.io';

const KKuTuIOApi = Axios.create({
  baseURL: `${API_HOST}/api/admin`,
  withCredentials: true
});

export { KKuTuIOApi };
