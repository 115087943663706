import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const SidebarOutLink = ({
  title,
  icon,
  newLink,
  link
}) => (
  <li className="sidebar__link" onClick={() => window.open(link)}>
    {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`}/> : ''}
    <p className="sidebar__link-title">
      {title}
      {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
    </p>
  </li>
);

SidebarOutLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  link: PropTypes.string
};

SidebarOutLink.defaultProps = {
  icon: '',
  newLink: false,
  link: '/'
};

export default SidebarOutLink;
